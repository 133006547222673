<template>
    <div class="w-full rounded-lg p-1 sm:rounded-3xl bg-white-2 sm:p-4 text-black-1 font-poppins flex gap-2">
        <div class="relative w-4/12">
            <img class="w-full h-full rounded-lg sm:rounded-3xl" :src="service.image" :alt="service.name">
        </div>

        <div class="card-body w-8/12 flex flex-col justify-between">
            <div class="font-bold h-auto text-xss sm:text-lg my-2">
                <h2 class="card-title text-sm sm:text-tiny">{{ service.name }}</h2>

                <span class="text-xss sm:text-sm">
                    <i class="fas fa-star text-yellow-300"></i> 
                    {{ Intl.NumberFormat("en-PH", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(service.average) }}
                </span>

                <div class="text-gray-500 sm:mt-3">
                    <p class="font-normal text-xs sm:text-tiny">{{ service.desc }}</p>
                </div>
            </div>
            
            <div class="flex justify-between items-center font-bold text-xss sm:text-tiny">
                <div class="srp-wrapper text-white-2 rounded-md" :style="{background: service.gender === 'male' ? '#489AD3':'#ecaba9'}">
                    <h2 class="card-title">P{{ new Intl.NumberFormat('en-PH').format(service.srp) }}</h2>
                </div>
                <h3>Duration: {{ service.duration }} mins</h3>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
      props: ['service'],

      watch: {
        service: {
            immediate: true,
            handler: function(service) {
                const image = service.image.split('/');
                const imgUrl =  image[image.length - 4] + "/" + image[image.length - 3] + "/" + image[image.length - 2] + "/" + image[image.length - 1];
                service.image = `${process.env.VUE_APP_IMAGE_BASE_URL}${imgUrl}`;
            }
        }
      }
  }
  </script>
  
  <style scoped>
  .hide-scroll::-webkit-scrollbar {
    width: 0 !important
  }

  .truncate-custom {
    max-width: 300px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  .srp-wrapper {
    padding: 2px 4px;
  }
  
  @media (max-width:480px) {
    .card-price{
        font-weight: 500;
    }

  }
  </style>
