<template>
    <div>
      <div class="w-screen section-container pn-services-banner relative space-top" v-if="hasNewSeasonalPage"></div>

      <div class="w-screen section-bg relative flex justify-end items-end xl:items-center space-top" v-else>
        <div class="absolute left-3 mb-14 md:mb-28 xl:mb-0 md:left-10 xl:left-24 2xl:left-32">
          <img class="w-80 md:w-106 xl:w-114 2xl:w-full" src="../../assets/images/services-page/generic-banners/pn-text.png" alt="generic-pn-description">
        </div>
        <img class="section-img -mr-36 md:-mr-20 xl:-mr-10 " src="../../assets/images/services-page/generic-banners/pn-model.png" alt="generic-pn-model">
      </div>
      
      <div class="w-screen service-we-offer py-4 mobileL:p-16" id="service-we-offer">
        <div class="content-wrapper max-w-7xl mx-auto text-center text-white-1">
          <h3 class="font-mermaid mobileL:font-kannada service-we-offer-content tracking-wider">Services we offer</h3>
          <p class="text-base mobileL:text-xl xl:text-2xl 2xl:text-4xl font-normal mobileL:font-semibold tracking-wide font-montserrat">Browse our full line of services</p>
        </div>
      </div>
  
      <div class="w-screen bg-violet-1 py-7 mobileL:py-10 px-5 mobileL:px-0 font-kannada text-white-1">
        <div class="relative w-5/12 mx-auto text-center grid mb-5 justify-center">
          <h2 class="font-bold text-3xl mobileL:text-5xl xl:text-6xl 2xl:text-7xl tracking-wider 2xl:tracking-widest text-center z-20">Single Services</h2>
          <img src="../../assets/images/services-page/sketch_line.png" alt="Sketch" class="w-16 xl:w-20 2xl:w-28 absolute -right-10 top-4 xl:-right-12 xl:top-4 2xl:-right-16 2xl:top-5">
          <span class="absolute bg-violet-3 w-full h-10 xl:h-12 2xl:h-16 bottom-3 xl:bottom-5 z-10"></span>
          <img class="absolute w-5 xl:w-7 2xl:w-8 -left-1 top-0 xl:left-0 xl:top-0 z-10" src="../../assets/images/home-page/shine.png" alt="Shine">
        </div>
  
        <div class="w-full mobileL:w-10/12 mx-auto mb-5">
          <div class="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 w-full gap-4 xl:gap-6">
            <ServiceCard v-for="(service, index) in maleServices" :key="index" :service="service" />
          </div>
          <center class="mt-8" v-if="servicesPage !== pnServicePageCount">
            <button class="underline text-xl xl:text-2xl 2xl:text-3xl" @click="loadMoreData()">See More</button>
          </center>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex';
  import ServiceCard from '../../components/ServiceCard.vue';
  export default {
    data() {
      return {
        servicesPage: 1,
        maleServices:[],
        windowWidth: window.innerWidth,
        hasNewSeasonalPage:true,
        requestLimit: 8
      }
    },
  
    components: {
      ServiceCard
    },
  
    methods: {
      ...mapActions('services', [
        'fetchServices',
        'fetchPackages',
        'fetchBusinessUnit',
      ]),
  
      loadMoreData() {
        this.servicesPage += 1;
        this.fetchServices({ business_unit_id: this.$passionailsId, page: this.servicesPage, limit: this.requestLimit });
      },

      handleResize() {
        this.windowWidth = window.innerWidth;
      },
    },

    mounted() {
      window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
  
    computed: {
      ...mapGetters('services', ['allService', 'allPackage', 'allBusinessUnit', 'allFemaleService', 'allMaleService', 'pnServicePageCount']),

      shouldShowElement() {
        return this.windowWidth > 480; 
      },

      shouldNotShowElement(){
        return this.windowWidth < 480; 
      },
    },
  
    created() {
      this.fetchServices({ business_unit_id: this.$passionailsId, limit: this.requestLimit });
    },

    watch: {
      allService: {
        immediate: true,
        handler: function(services) {
          if (!services.length) return;

          this.maleServices = services;
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .section-container {
      height: 600px;
  }
  
  .pn-services-banner {
    background: url('../../assets/images/services-page/seasonal-banners/PN_DEC_BANNER.jpg') no-repeat;
    background-size: 100% 100%;
  }

  
  .section-bg{
    background: url('../../assets/images/services-page/generic-banners/pn-background.jpg') no-repeat;
    background-size: cover;
  }

  .section-img{
    width:800px;
  }
  
  .space-top {
    margin-top: 90px;
  }
  
  .service-we-offer {
    background: url('../../assets/images/home-page/pn-banner-2.jpg') no-repeat;
    background-size: cover;
  }
  
  .service-we-offer-content {
    font-size: 4.25rem;
    font-weight: 600;
  }
  
  .services-bg {
    background: url('../../assets/images/home-page/body-background.jpg') no-repeat;
    background-size: cover;
  }
  
  .know-before-you-go-section {
    text-shadow: 6px 6px #6e554281;
  }

  @media (max-width:480px) {
    .section-container {
      height: 256px;
    }

    .pn-services-banner {
      background: url('../../assets/images/services-page/seasonal-banners/PN_DEC_BANNER.jpg') no-repeat;
      background-size: 100% 100%;
    }

    .section-img{
      width:750px;
    }
    
    .space-top {
      margin-top: 60px;
    }
    .service-we-offer-content {
      font-size: 32px;
      font-weight: 700;
    }
    
  }

  @media (min-width: 1280px) {
    .space-top {
      margin-top: 100px;
    }

    .section-container {
      height: calc(100vh - 100px);
    }

    .service-we-offer-content {
      font-size: 5.25rem;
    }
}

@media (min-width: 1536px) {
    .space-top {
      margin-top: 128px;
    }

    .section-container {
      height: 110vh;
    }

    .service-we-offer-content {
      font-size: 6.25rem;
    }
}
  
  </style>